<template>
	<section class="cd-hwa">
		<div class="cd-hwa__header">
			<h2 class="cd-hwa__title">
				{{ homework_attempt.homework.lecture.name }}
			</h2>
			<div class="cd-hwa__meta cd-meta">
				<dl class="cd-meta__list">
					<div class="cd-meta__item">
						<dt>Компания</dt>
						<dd>{{ homework_attempt.user.company.name }}</dd>
					</div>
					<div class="cd-meta__item">
						<dt>Курс</dt>
						<dd>
							{{ homework_attempt.homework.lecture.course.name }} || {{ homework_attempt.homework.lecture.course_id }}
						</dd>
					</div>
					<div class="cd-meta__item">
						<dt>Имя студента</dt>
						<dd>{{ homework_attempt.user.name }}</dd>
					</div>
					<div class="cd-meta__item">
						<dt>Email студента</dt>
						<dd>
							<a :href="`${homework_attempt.user.email}`" target="_blank" rel="noopener noreferrer">{{ homework_attempt.user.email }}</a>
						</dd>
					</div>
					<div class="cd-meta__item">
						<dt>Дата отправки</dt>
						<dd>{{ formatDate(homework_attempt.created_at) }}</dd>
					</div>
				</dl>
			</div>
		</div>

		<section class="cd-hwa__section">
			<h3 class="cd-hwa__subtitle">Настройки</h3>
			<div class="cd-hwa__card">
				<div class="cd-hwa__group-field">
					<label class="labeled-field labeled-field--select flex-grow" for="id-homework-reviewer">
						<span class="labeled-field__label">Проверяет:</span>
						<select id="id-homework-reviewer" class="labeled-field__input" v-model="selectedReviewer">
							<option value="">Выберите проверяющего</option>
							<option v-for="reviewer in reviewers" :key="reviewer.id" :value="reviewer.id">
								{{ reviewer.email }}
							</option>
						</select>
					</label>

					<label class="labeled-field labeled-field--select flex-grow" for="id-homework-crosschecker">
						<span class="labeled-field__label">Кроссчекает:</span>
						<select id="id-homework-crosschecker" class="labeled-field__input" v-model="selectedCrossChecker">
							<option value="">Выберите кроссчекера</option>
							<option v-for="checker in reviewers" :key="checker.id" :value="checker.id">
								{{ checker.email }}
							</option>
						</select>
					</label>

					<label class="labeled-field labeled-field--select flex-grow" for="id-homework-status">
						<span class="labeled-field__label">Статус ДЗ:</span>
						<select id="id-homework-status" class="labeled-field__input" v-model="selectedStatus">
							<option v-for="status in statuses" :key="status.value" :value="status.value">
								{{ status.text }}
							</option>
						</select>
					</label>
				</div>
				<button @click="submitAllChanges" class="regular-btn light-blue-btn mt-10" type="button" :disabled="isButtonDisabled">
					Применить изменения
				</button>
			</div>
		</section>

		<section class="cd-hwa__section">
			<h3 class="cd-hwa__subtitle">Текст задания</h3>
			<div class="cd-hwa__card">
				<div class="longread longread--no-pads" v-html="homework_attempt.homework.body"></div>
			</div>
		</section>

		<section class="cd-hwa__section">
			<h3 class="cd-hwa__subtitle">Ответ пользователя</h3>
			<div class="cd-hwa__card">
				<template v-if="homework_attempt.answer">
					<div class="longread longread--no-pads">
						{{ homework_attempt.answer }}
					</div>

					<template v-if="homework_attempt.attachments.length">
						<h3 class="label mb-5 mt-20">Прикрепленные файлы</h3>
						<div class="files-preview-list">
							<div
								v-for="(attachment, index) in homework_attempt.attachments"
								:key="index"
								class="files-preview-list__item"
							>
								<span class="files-preview-list__check icon icon--check icon--tiny icon--high-color" />
								<a :href="attachment.file_url_original" target="_blank" rel="noopener noreferrer" class="files-preview-list__name">{{ attachment.file_name }}</a>
							</div>
						</div>
					</template>
				</template>
				<template v-else>
					Студент пока не прислал свой ответ
				</template>
			</div>
		</section>

		<section class="cd-hwa__section">
			<h3 class="cd-hwa__subtitle">Комментарии проверяющих</h3>
			<div class="cd-hwa__card">
				<button class="link-btn" @click="showComments">
					<template v-if="isShowComments">- Скрыть комментарии проверяющих</template>
					<template v-else>+ Показать комментарии проверяющих</template>
				</button>
				<div v-show="isShowComments" class="mt-20">
					<ul v-if="homework_attempt.homework_attempt_comments.length">
						<li v-for="(comment, index) in homework_attempt.homework_attempt_comments" :key="index" class="cd-comment">
							<div class="cd-comment__item">
								<h3 class="cd-comment__title">{{ comment.user.first_name }} {{ comment.user.last_name }}</h3>
								<div class="cd-comment__meta">
									<span>{{ comment.user.email }}</span>
									<span>{{ formatDate(comment.created_at) }}</span>
								</div>
								<div class="cd-comment__text">
									{{ comment.body }}
								</div>
							</div>
						</li>
					</ul>

					<div class="page-card__col page-card__col--main mt-30">
						<label class="labeled-field" for="id-homework-comment">
							<span class="labeled-field__label">Ваш комментарий:</span>
							<textarea
								id="id-homework-comment"
								class="labeled-field__input"
								v-model="attemptComment"
								placeholder="Напишите здесь свой комментарий"
							></textarea>
						</label>
						<button class="regular-btn light-blue-btn mt-10" @click="submitAttemptComment" type="button">Отправить комментарий коллегам</button>
					</div>
				</div>
			</div>
		</section>

		<section class="cd-hwa__section">
			<h3 class="cd-hwa__subtitle">Ваш ответ</h3>
			<div class="cd-hwa__card">
				<label class="labeled-field" for="id-homework-control">
					<span class="labeled-field__label">Ответ студенту:</span>
					<textarea
						id="id-homework-control"
						class="labeled-field__input"
						v-model="controlComment"
						placeholder="Напишите здесь свой ответ"
					></textarea>
				</label>
				<button class="regular-btn start-btn mt-10"
						@click="submitControlComment"
						type="button"
						:disabled="!['success_done', 'failed_done'].includes(homework_attempt.status)"
				>
					Отправить ответ студенту
				</button>
				<p v-if="!['success_done', 'failed_done'].includes(homework_attempt.status)" class="button-info">
					Пожалуйста, установите и примените статус на "Провалено" или "Успешно выполнено" для активации кнопки
				</p>

				<div v-if="homework_attempt.control" class="mt-20">
					<h3 class="label mb-5">Ваш ответ</h3>
					<div>{{ homework_attempt.control }}</div>
				</div>
			</div>
		</section>
	</section>
</template>

<script>
export default {
	name: 'HomeworkAttempt',

	props: {
		homework_attempt: { type: Object },
		reviewers: { type: Array },
		homework_attempt_comments_url: { type: String },
		homework_attempt_url: { type: String },
		list_statuses: { type: Array, required: true }
	},

	data() {
		return {
			selectedReviewer: this.homework_attempt.reviewer_id || '',
			selectedCrossChecker: this.homework_attempt.cross_checker_id || '',
			selectedStatus: this.homework_attempt.status,
			statuses: this.list_statuses,
			controlComment: '',
			attemptComment: '',
			isShowComments: false,
			isSubmitFields: {
				reviewer_id: false,
				cross_checker_id: false,
				status: false,
			},
			isButtonDisabled: true
		};
	},

	computed: {
	},

	mounted() {
		if (sessionStorage.isShowComments) {
			this.isShowComments = sessionStorage.isShowComments === 'true';
		}
	},

	watch: {
		isShowComments(newValue) {
			sessionStorage.isShowComments = newValue;
		},
		selectedReviewer(newVal) {
			this.checkChanges();
		},
		selectedCrossChecker(newVal) {
			this.checkChanges();
		},
		selectedStatus(newVal) {
			this.checkChanges();
		}
	},

	methods: {
		checkChanges() {
			const reviewerChanged = this.selectedReviewer !== (this.homework_attempt.reviewer_id || '');
			const crossCheckerChanged = this.selectedCrossChecker !== (this.homework_attempt.cross_checker_id || '');
			const statusChanged = this.selectedStatus !== (this.homework_attempt.status || '');

			// Если есть изменения, делаем кнопку активной
			this.isButtonDisabled = !(reviewerChanged || crossCheckerChanged || statusChanged);
		},

		formatDate(dateString) {
			const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
			return new Date(dateString).toLocaleDateString('ru-RU', options);
		},

		submitAttemptComment() {
			fetch(this.homework_attempt_comments_url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/javascript',
					'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
				},
				body: JSON.stringify({
					body: this.attemptComment,
				}),
			})
				.then(response => response.json())
				.then(comment => {
					this.homework_attempt.homework_attempt_comments.push(comment);
					this.attemptComment = '';
				})
				.catch((error) => {
					console.error('Error:', error);
				});
		},

		submitControlComment() {
			this.updateHomeworkAttempt('control', this.controlComment);
		},

		submitAllChanges() {
			// Создаем объект payload для отправки всех изменений
			let payload = { homework_attempt: {} };

			// Добавляем данные в payload, если они изменены
			if (this.selectedReviewer !== this.homework_attempt.reviewer_id) {
				payload.homework_attempt['reviewer_id'] = this.selectedReviewer;
			}
			if (this.selectedCrossChecker !== this.homework_attempt.cross_checker_id) {
				payload.homework_attempt['cross_checker_id'] = this.selectedCrossChecker;
			}
			if (this.selectedStatus !== this.homework_attempt.status) {
				payload.homework_attempt['status'] = this.selectedStatus;
			}

			// Проверяем, есть ли изменения, которые нужно отправить
			if (Object.keys(payload.homework_attempt).length === 0) {
				alert('Нет изменений для сохранения.');
				return;
			}

			// Отправляем запрос
			fetch(this.homework_attempt_url, {
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/javascript',
					'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
				},
				body: JSON.stringify(payload),
			})
				.then(response => {
					if (!response.ok) {
						throw new Error('Network response was not ok');
					}
					return response.json();
				})
				.then(data => {
					if (data.success) {
						// Обновляем локальные данные напрямую
						if ('reviewer_id' in payload.homework_attempt) {
							this.homework_attempt.reviewer_id = this.selectedReviewer;
						}
						if ('cross_checker_id' in payload.homework_attempt) {
							this.homework_attempt.cross_checker_id = this.selectedCrossChecker;
						}
						if ('status' in payload.homework_attempt) {
							this.homework_attempt.status = this.selectedStatus;
						}

						this.isButtonDisabled = true;

						// Показываем уведомление об успешном обновлении
						this.showCheckSubmit('all_fields');
						console.log('Все изменения успешно применены.');
					} else {
						console.error('Ошибка обновления:', data.errors.join('; '));
						alert('Ошибка обновления: ' + data.errors.join('; '));
					}
				})
				.catch(error => {
					console.error('Error:', error);
					alert('Ошибка: ' + error.message);
				});
		},

		showCheckSubmit(field) {
			this.isSubmitFields[field] = true;

			setTimeout(() => {
				this.isSubmitFields[field] = false;
			}, 3000);
		},

		updateHomeworkAttempt(field, value) {
			let payload = { homework_attempt: {}};
			payload["homework_attempt"][field] = value;

			fetch(this.homework_attempt_url, {
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/javascript',
					'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
				},
				body: JSON.stringify(payload),
			})
				.then(response => {
					if (!response.ok) {
						throw new Error('Network response was not ok');
					}
					return response.json();
				})
				.then(data => {
					if (data.success) {
						this.homework_attempt[field] = value;

						if (field === 'control') {
							this.controlComment = '';
						}

						this.showCheckSubmit(field);

						console.log(`${field} updated successfully`);
					} else {
						console.error(`Error updating ${field}:`, data.errors.join('; '));
						alert(`Ошибка обновления ${field}: ` + data.errors.join('; '));
					}
				})
				.catch((error) => {
					console.error('Error:', error);
					alert('Ошибка: ' + error.message);
				});
		},

		showComments() {
			this.isShowComments = !this.isShowComments;
		}
	}
}
</script>

<style scoped>
.button-info {
	color: #d9534f;
	font-size: 0.9em;
	margin-top: 5px;
}
</style>
