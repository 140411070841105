<template>
	<section class="modal modal--half-path modal--half-path-referral  showing j-popup">
		<div class="modal__card modal__card--small">
			<div class="modal__image-holder">
				<img class="modal__header-image" src="../../../assets/images/half-path-popup/half-path-popup.svg" alt="">
				<h2 class="modal__title modal__title--large">
					Рекомендуйте Академию Eduson&nbsp;и&nbsp;зарабатывайте <span class="icon icon--huge icon--money-bag"></span>
				</h2>
			</div>
			<div class="modal__main longread">
				<ul>
					<li>Пройдите регистрацию, она займет меньше минуты</li>
					<li>Скопируйте уникальную ссылку, которая появится после регистрации</li>
					<li>Передайте ссылку друзьям, коллегам или разместите в&nbsp;социальных сетях</li>
					<li>Дождитесь пока по вашей ссылке купят курс</li>
					<li>Получите 16% от суммы, которую оплатят по вашей ссылке</li>
				</ul>
				<footer class="text-center mt-20">
					<a
						href="#id-referral-banner"
						class="large-btn academy-btn j-hide-popup j-upd-additional-info"
						:data-url="data_url"
						data-additional-info="half-path-popup-2024-07-yes"
						style="color: #2d2d2d !important;"
					>Регистрация</a>
					<button
						class="large-btn custom-btn ml-10 j-hide-popup j-upd-additional-info"
						type="button"
						:data-url="data_url"
						data-additional-info="half-path-popup-2024-07-no"
					>Пока не готов</button>
				</footer>
			</div>
		</div>
	</section>
</template>

<script>
import referralCourses from '../../common/referral-courses.js';

export default {
	name: 'HalfPathPopup',

	props: {
		current_company: '',
		data_url: ''
	},

	data() {
		return {
			courses: referralCourses,
			isShow: true
		}
	},

	mounted() {
		this.courses.forEach((course) => {
			if (course === this.current_company) {
				this.isShow = false;
			}
		});
	},
}
</script>
