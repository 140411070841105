const referralCourses = [
	'academy-expert-gen-director-demo',
	'academy-1c-demo',
	'academy-1c-excel-demo',
	'academy-accountant-beginner-demo',
	'academy-accountant-demo',
	'academy-accountant-kz-demo',
	'academy-analytics-tools-demo',
	'academy-business-analyst-demo',
	'academy-business-smm-demo',
	'academy-chief-accountant-demo',
	'academy-chief-of-operations-demo',
	'academy-com-director-demo',
	'academy-copywriter-demo',
	'academy-copywriting-demo',
	'academy-data-analyst-demo',
	'academy-data-science-demo',
	'academy-demo-bi-analyst',
	'academy-director-economy-finance-demo2024',
	'academy-director-economy-finance-pro-demo2024',
	'academy-director-of-education-demo',
	'academy-director-of-strategy-demo',
	'academy-economist-financier-demo',
	'academy-emba-demo',
	'academy-entrepreneur-start-demo',
	'academy-excel-buhgalter-demo',
	'academy-excel-demo',
	'academy-excel-demo.eduson',
	'academy-excel-pro-demo',
	'academy-executive-director-demo',
	'academy-fin-analyst-demo',
	'academy-fin-business-demo',
	'academy-fin-modeling-demo',
	'academy-fin-officer-demo',
	'academy-fin-officer-kz-demo',
	'academy-finance-non-finance-demo',
	'academy-frontend-developer-demo',
	'academy-fullstack-developer-demo',
	'academy-fullstack-developer-pro-demo',
	'academy-gen-director-demo',
	'academy-gen-director-pro-demo',
	'academy-graphic-designer-demo',
	'academy-graphic-designer-freelance-demo',
	'academy-graphic-designer-it-demo',
	'academy-hr-analytics-demo',
	'academy-hr-business-partner-demo-old',
	'academy-hr-director-demo',
	'academy-hr-for-business-demo',
	'academy-hr-manager-demo',
	'academy-internet-marketing-demo',
	'academy-kdp-demo',
	'academy-manager-designer-demo',
	'academy-marketing-director-demo',
	'academy-marketplace-design-demo',
	'academy-marketplace-manager-demo',
	'academy-marketplace-manager-pro-demo',
	'academy-marketplace-marketing-demo',
	'academy-marketplace-seller-demo',
	'academy-marketplace-seller-pro-demo',
	'academy-mba-commerce-demo',
	'academy-mba-digital-business-demo',
	'academy-mba-enterprise-management-demo2024',
	'academy-mba-finance-master-demo2024',
	'academy-mba-hr-demo',
	'academy-mba-leadership-demo',
	'academy-mba-marketing-demo',
	'academy-mba-operations-demo',
	'academy-methodist-basics-demo',
	'academy-mini-mba-demo',
	'academy-msfo-demo',
	'academy-msoffice-demo',
	'academy-online-course-creation-demo',
	'academy-power-bi-demo',
	'academy-product-manager-demo',
	'academy-product-researcher-demo',
	'academy-project-management-demo',
	'academy-project-manager-demo',
	'academy-project-manager-it-demo',
	'academy-psychology-demo',
	'academy-public-speech-demo',
	'academy-python-developer-demo',
	'academy-qa-demo',
	'academy-qa-pro-demo',
	'academy-recruiting-demo',
	'academy-remote-management-demo',
	'academy-sales-demo',
	'academy-sales-dir-demo',
	'academy-sales-mba-demo',
	'academy-salesman-demo',
	'academy-smm-demo',
	'academy-soft-skills-basic-demo',
	'academy-soft-skills-demo',
	'academy-soft-skills-for-women-demo',
	'academy-sql-demo',
	'academy-startup-demo',
	'academy-systems-analyst-demo',
	'academy-team-management-demo',
	'academy-web-design-demo',
	'academy-web-design-profession-demo',
	'academy-web-developer-demo',
	'academy-web-developer-pro-demo',
	'excel-finances-demo',
	'psychologist-demo',
	'traffic-manager-demo',
	'academy-wildberries-free',
	'academy-python-dev-beginner',
	'academy-data-analyst-free-demo',
	'academy-copywriter-start',
	'academy-marketplace-free',
	'academy-mini-accountant',
	'academy-web-developer-start',
	'academy-web-developer-first-project',
	'academy-company-finances',
	'academy-sales-team',
	'academy-product-quick-start',
	'academy-project-quick-start',
	'academy-internet-marketing-introduction',
	'academy-unit-metrics'
];

export default referralCourses;
