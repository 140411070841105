<template>
	<div class="j-catalogue-course-sets-holder">
		<ProgramsGroup
			:loadingData="loadingData"
			:programs="defaultPrograms"
			:newProgramUrl="newProgramUrl"
			:title="programsGroupTitle"
			:create="createProgram"
			:showMore="showOtherPrograms"
			:deleteDraftBtn="deleteDraftBtn"
			:emptyDraftsExists="emptyDraftsExists"
			:eraseDraftsProgramsUrl="eraseDraftsProgramsUrl"
			:areYouSure="areYouSure"
		/>

		<ProgramsGroup
			v-if="fastPrograms.length"
			:loadingData="loadingData"
			:programs="fastPrograms"
			:newProgramUrl="newProgramUrl"
			:title="fastProgramsGroupTitle"
			:create="createProgram"
			:showMore="showOtherPrograms"
			:deleteDraftBtn="deleteDraftBtn"
			:emptyDraftsExists="emptyDraftsExists"
			:eraseDraftsProgramsUrl="eraseDraftsProgramsUrl"
			:areYouSure="areYouSure"
			:isShowProgramsStubBtn="false"
		/>

		<ProgramsGroup
			:loadingData="loadingData"
			:programs="onboardingPrograms"
			:newProgramUrl="newOnboardingProgramUrl"
			:title="onboardingProgramsGroupTitle"
			:create="createProgram"
			:showMore="showOtherPrograms"
			:deleteDraftBtn="deleteDraftBtn"
			:emptyDraftsExists="false"
			:eraseDraftsProgramsUrl="eraseDraftsProgramsUrl"
			:areYouSure="areYouSure"
		/>
	</div>
</template>

<script>
import axios from 'axios'
import ProgramsGroup from './ProgramsGroup.vue'

export default {
	name: 'ProgramsList',

	components: {
		ProgramsGroup
	},

	props: {
		url: { type: String, required: true }
	},

	data() {
		return {
			defaultPrograms: [],
			onboardingPrograms: [],
			fastPrograms: [],
			loadingData: false,
			programsGroupTitle: 'Список программ обучения',
			onboardingProgramsGroupTitle: 'Список программ адаптации',
			fastProgramsGroupTitle: 'Список быстрых назначений',
			createProgram: 'Создать программу',
			showOtherPrograms: '+ Показать остальные программы',
			newProgramUrl: '#',
			newOnboardingProgramUrl: '#',
			deleteDraftBtn: 'Удалить пустые черновики',
			emptyDraftsExists: false,
			eraseDraftsProgramsUrl: '#',
			areYouSure: 'Вы уверены?'
		}
	},

	mounted() {
		this.loadingData = true;

		axios.request({
			response_type: 'json',
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
			},
			url: this.url
		}).then((response) => {
			this.defaultPrograms = response.data.default_programs;
			this.onboardingPrograms = response.data.onboarding_programs;
			this.fastPrograms = response.data.fast_programs;
			this.programsGroupTitle = response.data.programs_group_title;
			this.onboardingProgramsGroupTitle = response.data.onboarding_programs_group_title;
			this.createProgram = response.data.create_title;
			this.showOtherPrograms = response.data.show_other_programs_title;
			this.newProgramUrl = response.data.new_program_url;
			this.newOnboardingProgramUrl = response.data.new_onboarding_program_url;
			this.loadingData = false;
			this.deleteDraftBtn = response.data.delete_draft_btn;
			this.emptyDraftsExists = response.data.empty_drafts_exists;
			this.eraseDraftsProgramsUrl = response.data.erase_drafts_programs_url;
			this.areYouSure = response.data.are_you_sure;
		}).catch((error) => {
			console.error(error.response.data);
		})
	},
}
</script>
