<template>
	<div v-if="isShow" class="academy-nav-item">
		<a href="#id-referral-banner" class="academy-nav-link academy-nav-link--referral">Рекомендовать курсы</a>
	</div>
</template>

<script>
import referralCourses from '../../common/referral-courses.js';

export default {
	name: 'ReferralLink',

	props: {
		current_company: ''
	},

	data() {
		return {
			courses: referralCourses,
			isShow: true
		}
	},

	created() {
		this.courses.forEach((course) => {
			if (course === this.current_company) {
				this.isShow = false;
			}
		});
	},
}
</script>
