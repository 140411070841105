<template>
	<section class="cd-homework">
		<div class="cd-homework__title">{{ $t('checking_homework') }}</div>
		<div class="cd-homework__content">
			<div class="cd-homework__leftbar users-leftbar">
				<h2 class="users-leftbar__main-title">{{ $t('filters') }}</h2>
				<div class="users-leftbar__section">
					<h3 class="users-leftbar__title">{{ $t('status') }}</h3>
					<ul class="users-leftbar__list">
						<li v-for="(status, index) in homeworkStatuses"  :key="'status_' + index">
							<label class="checkbox checkbox--small mb-10">
								<input
									type="checkbox"
									class="checkbox__input"
									:value="status"
									v-model="homeworkStatusesModel"
								>
								<span class="checkbox__label">{{ $t(`homework_statuses.${status}`) }}</span>
							</label>
						</li>
					</ul>
				</div>
				<div class="users-leftbar__section users-leftbar__section--top-bordered">
					<label class="checkbox checkbox--small mb-10">
						<input
							type="checkbox"
							class="checkbox__input"
							v-model="homeworkCompletedModel"
						>
						<span class="checkbox__label">{{ $t('hide_completed_homework_attempts') }}</span>
					</label>
				</div>
				<div class="users-leftbar__section users-leftbar__section--top-bordered">
					<button @click="resetFilters" class="small-btn white-btn" type="button">{{ $t('buttons.clear_filters') }}</button>
				</div>
			</div>
			<div class="cd-homework__main">
				<label class="cd-homework__search labeled-field labeled-field--search mb-10">
					<input @input="searchOnInput" ref="searchInput" placeholder="Поиск" type="text" class="labeled-field__input" v-model="searchModel">
					<button @click="clearSearch" ref="clearSearchButton" class="remove-btn remove-btn--tiny remove-btn--red hide" type="button"></button>
				</label>
				<div class="cd-homework__table-wrap">
					<table class="table table--tr-hover table--statistics table--homework">
						<thead class="table__thead">
							<tr class="table__tr">
								<th class="table__th" @click="sortTextColumn('status_with_translate', $event)" data-sorted="">
									<span>Статус ДЗ</span>
								</th>
								<th class="table__th" @click="sortTextColumn('name', $event)" data-sorted="">
									<span>Название ДЗ</span>
								</th>
								<th class="table__th" @click="sortTextColumn('company', $event)" data-sorted="">
									<span>Компания</span>
								</th>
								<th class="table__th" @click="sortTextColumn('student', $event)" data-sorted="">
									<span>Студент</span>
								</th>
								<th class="table__th" @click="sortTextColumn('reviewer', $event)" data-sorted="">
									<span>Проверяет</span>
								</th>
								<th class="table__th" @click="sortTextColumn('cross_checker', $event)" data-sorted="">
									<span>Кроссчекает</span>
								</th>
								<th class="table__th" @click="sortNumericColumn('days_on_review', $event)" data-sorted="">
									<span>Дней на проверке</span>
								</th>
							</tr>
						</thead>
						<tbody class="table__body">
							<template v-if="filteredHomeworkAttempts.length">
								<tr v-for="attempt in filteredHomeworkAttempts" @click="goToShowPage(attempt.id)" class="table__tr">
									<td class="table__td">
										<span class="icon icon--small icon--dot" :style="`--icon-color: ${getStatusColor(attempt.status)};`">
											{{ attempt.status_with_translate }}
										</span>
									</td>
									<td class="table__td">
										<span class="bold-font">{{ attempt.name }}</span>
									</td>
									<td class="table__td">
										{{ attempt.company }}
									</td>
									<td class="table__td">
										<a :href="`mailto:${attempt.student}`">{{ attempt.student }}</a>
									</td>
									<td class="table__td">
										<template v-if="attempt.reviewer">
											<a :href="`mailto:${attempt.reviewer}`">{{ attempt.reviewer }}</a>
										</template>
									</td>
									<td class="table__td">
										<template v-if="attempt.cross_checker">
											<a :href="`mailto:${attempt.cross_checker}`">{{ attempt.cross_checker }}</a>
										</template>
									</td>
									<td class="table__td">
										{{ attempt.days_on_review }}
									</td>
								</tr>
							</template>
							<template v-else>
								<tr>
									<td colspan="7" class="table__no-result">
										{{ $t('messages.no_filters_message') }}
									</td>
								</tr>
							</template>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'HomeworkAttempts',

	props: {
		homework_attempts: { type: Array }
	},

	mounted() {
		this.homeworkAttempts = this.getHomeworkAttempts();
	},

	data() {
		return {
			homeworkAttempts: [],
			homeworkStatuses: ['sent', 'checking', 'in_crosscheck', 'checked', 'sending', 'success_done', 'failed_done'],
			homeworkStatusesModel: [],
			homeworkCompletedModel: false,
			searchModel: ''
		}
	},

	computed: {
		filteredHomeworkAttempts() {
			let attempts = this.homeworkAttempts;

			if (this.searchModel.length !== 0) {
				let query = this.searchModel.toLowerCase();

				attempts = attempts.filter((attempt) => {
					return (
						attempt.name.toLowerCase().includes(query) ||
						attempt.status_with_translate.toLowerCase().includes(query) ||
						attempt.company.toLowerCase().includes(query) ||
						attempt.student.toLowerCase().includes(query) ||
						attempt.reviewer.toLowerCase().includes(query) ||
						attempt.cross_checker.toLowerCase().includes(query)
					)
				})
			}

			if (this.homeworkStatusesModel.length !== 0) {
				attempts = attempts.filter(
					(attempt) => this.homeworkStatusesModel.includes(attempt.status)
				)
			}

			if (this.homeworkCompletedModel) {
				attempts = attempts.filter(
					(attempt) => attempt.status !== 'success_done'
				)
			}

			return attempts;
		}
	},

	methods: {
		getHomeworkAttempts() {
			let arr = [];

			this.homework_attempts.forEach((attempt) => {
				let obj = {}
				obj.id = attempt.id
				obj.status = attempt.status ?? '';
				obj.status_with_translate = attempt.status_with_translate ? attempt.status_with_translate.text : '';
				obj.name = attempt.homework ? attempt.homework.lecture.name : '';
				obj.company = attempt.user ? attempt.user.company.name : '';
				obj.student = attempt.user ? attempt.user.email : '';
				obj.reviewer = attempt.reviewer ? attempt.reviewer.email : '';
				obj.cross_checker = attempt.cross_checker ? attempt.cross_checker.email : '';
				obj.days_on_review = attempt.created_at ? this.calculateDaysOnReview(attempt.created_at).toString() : '';
				arr.push(obj);
			});

			return arr;
		},

		calculateDaysOnReview(createdAt) {
			return Math.ceil((new Date() - new Date(createdAt)) / (1000 * 60 * 60 * 24));
		},

		goToShowPage(attemptId) {
			window.location.href = `/dashboard/homework_attempts/${attemptId}`;
		},

		getStatusColor(status) {
			switch(status) {
				case 'sent':
					return 'hsl(320, 90%, 50%)';
				case 'checking':
					return 'hsl(280, 90%, 50%)';
				case 'in_crosscheck':
					return 'hsl(60, 90%, 40%)';
				case 'checked':
					return 'hsl(220, 90%, 50%)';
				case 'sending':
					return 'hsl(180, 90%, 50%)';
				case 'success_done':
					return 'hsl(120, 90%, 40%)';
				case 'failed_done':
					return 'hsl(20, 90%, 50%)';
				default:
					return 'hsl(120, 90%, 20%)';
			}
		},

		sortTextColumn(column, e) {
			let el = e.currentTarget;

			if (el.dataset.sorted !== 'desc') {
				this.homeworkAttempts = this.homeworkAttempts.sort((a, b) => a[column].localeCompare(b[column]));
				el.dataset.sorted = 'desc';
				el.classList.add('selected');
				el.classList.remove('down');
			} else {
				this.homeworkAttempts = this.homeworkAttempts.sort((b, a) => a[column].localeCompare(b[column]));
				el.dataset.sorted = 'asc';
				el.classList.add('selected');
				el.classList.add('down');
			}
		},

		sortNumericColumn(column, e) {
			let el = e.currentTarget;

			if (el.dataset.sorted !== 'desc') {
				this.homeworkAttempts = this.homeworkAttempts.sort((a, b) => Number(a[column]) - Number(b[column]));
				el.dataset.sorted = 'desc';
				el.classList.add('selected');
				el.classList.remove('down');
			} else {
				this.homeworkAttempts = this.homeworkAttempts.sort((a, b) => Number(b[column]) - Number(a[column]));
				el.dataset.sorted = 'asc';
				el.classList.add('selected');
				el.classList.add('down');
			}
		},

		resetFilters() {
			this.homeworkStatusesModel = [];
			this.homeworkCompletedModel = false;
		},

		searchOnInput() {
			if (this.searchModel.length) {
				this.$refs.clearSearchButton.classList.remove('hide');
			} else {
				this.$refs.clearSearchButton.classList.add('hide');
			}
		},

		clearSearch() {
			this.$refs.searchInput.value = '';
			this.searchModel = '';
			this.$refs.clearSearchButton.classList.add('hide');
		}
	}
}
</script>
